import { motion } from "framer-motion";
import DataScienceAnim from "../Anims/DataScienceAnim";
import MLAnimWithProvider from "../Anims/MLAnim";
import SimEngWithProvider from "../Anims/SimEngAnim";
import { forwardRef, useRef } from "react";

const Interests = forwardRef((props, ref) => {
  const deviceWidth = useRef(window.innerWidth);

  return (
    <div ref={ref} className="w-full bg-[#181818] relative overflow-hidden">
      <div
        className="
        mt-5
      w-full md:h-screen flex flex-col gap-5 md:gap-3 justify-center"
      >
        <div className="p-3 flex flex-col items-center justify-center gap-3 md:gap-5 md:px-11 md:flex-row">
          {[
            <SimEngWithProvider />,
            <MLAnimWithProvider />,
            <DataScienceAnim />,
          ].map((it, index) => (
            <motion.div
              key={index}
              initial={{
                y: 200,
                scale: 0.7,
                opacity: 0,
              }}
              whileInView={{
                y: 0,
                scale: 1,
                opacity: 1,
              }}
              viewport={{ once: true }}
              transition={{
                duration: 0.8,
                delay: deviceWidth.current > 768 ? index * 0.2 : 0,
                type: "tween",
              }}
              className="p-2 w-full h-[97%]"
            >
              {it}
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Interests;
